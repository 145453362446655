import { useState, useEffect, useMemo } from "react";

function MarketTable({ markets }) {
  const [limit, setLimit] = useState("all");
  const [sortConfig, setSortConfig] = useState({
    key: "volatility",
    direction: "descending",
  });
  const [selectedCategories, setSelectedCategories] = useState({
    sports: false,
    politics: true,
    business: true,
    culture: true,
    other: true,
  });
  const [filterVolume, setFilterVolume] = useState(true); // Checkbox state
  const [displayedMarkets, setDisplayedMarkets] = useState([]); // Stores filtered markets

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const filteredMarkets = useMemo(() => {
    let sortableMarkets = [...markets];

    // Filter by selected categories
    const selectedKeys = Object.keys(selectedCategories).filter(
      (key) => selectedCategories[key]
    );

    sortableMarkets = sortableMarkets.filter((market) => {
      // If 'other' category is selected, include markets that don't match predefined categories
      if (
        selectedCategories.other &&
        !["sports", "politics", "business", "culture"].includes(
          market.classification.toLowerCase()
        )
      ) {
        return true;
      }

      // Otherwise, filter based on the selected categories
      return (
        market.classification &&
        selectedKeys.includes(market.classification.toLowerCase())
      );
    });

    // Exclude markets with volume < $1000 if filterVolume is true
    if (filterVolume) {
      sortableMarkets = sortableMarkets.filter(
        (market) => market.volume >= 1000
      );
    }

    // Filter out markets with odds close to 0 or 1 (tolerance of 0.01)
    sortableMarkets = sortableMarkets.filter(
      (obj) => Math.abs(obj.odds - 1) > 0.01 && Math.abs(obj.odds - 0) > 0.01
    );

    // Sort by the specified configuration
    if (sortConfig.key) {
      sortableMarkets.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // If sorting by 'imp', calculate it as volume * volatility
        if (sortConfig.key === "imp") {
          aValue = a.volume * a.volatility;
          bValue = b.volume * b.volatility;
        }

        // Convert values to numbers for sorting, if the key is volume, volatility, or imp
        if (["volume", "volatility"].includes(sortConfig.key)) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (limit === "all") {
      return sortableMarkets;
    }
    return sortableMarkets.slice(0, limit);
  }, [markets, limit, sortConfig, selectedCategories, filterVolume]);

  useEffect(() => {
    setDisplayedMarkets(filteredMarkets);
  }, [filteredMarkets]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : true;
  });

  useEffect(() => {
    const handleDarkModeChange = (event) => {
      const isDarkMode = event.detail;
      setDarkMode(isDarkMode);
      if (isDarkMode) {
        setLimit("all");
      } else {
        setLimit(50);
      }
    };

    window.addEventListener("darkModeChange", handleDarkModeChange);

    return () => {
      window.removeEventListener("darkModeChange", handleDarkModeChange);
    };
  }, []);

  return (
    <div style={{ marginTop: "4%" }}>
      <p className="txt-green">
        <i>🔮 Markets sorted by 24 hour volatility</i>
      </p>

      <div className="category-filters mb-3">
        <label>Sort:</label>
        <label>
          <input
            type="checkbox"
            checked={selectedCategories.sports}
            onChange={() => handleCategoryChange("sports")}
          />
          <span className="ps-2">Sports</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCategories.politics}
            onChange={() => handleCategoryChange("politics")}
          />
          <span className="ps-2">Politics</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCategories.business}
            onChange={() => handleCategoryChange("business")}
          />
          <span className="ps-2">Business</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCategories.culture}
            onChange={() => handleCategoryChange("culture")}
          />
          <span className="ps-2">Culture</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCategories.other}
            onChange={() => handleCategoryChange("other")}
          />
          <span className="ps-2">Other</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={filterVolume}
            onChange={() => setFilterVolume((prev) => !prev)}
          />
          <span className="ps-2">Exclude Markets with Volume &lt; $1000</span>
        </label>
      </div>

      {!darkMode && (
        <div className="mb-3 row g-4 table-filters">
          <div className="col-md-3 d-flex align-items-center justify-content-start">
            <label className="me-2 txt-green">Limit Records:</label>
            <select
              className="form-select w-auto"
              value={limit}
              onChange={(e) =>
                setLimit(
                  e.target.value === "all" ? "all" : parseInt(e.target.value)
                )
              }
            >
              <option value={50}>50</option>
              <option value={150}>150</option>
              <option value={250}>250</option>
              <option value={350}>350</option>
              <option value={500}>500</option>
              <option value="all">All</option>
            </select>
          </div>
        </div>
      )}

      <div className="table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr className="text-center">
              <th
                className="txt-green py-3 ps-2"
                style={{ width: "550px" }}
                scope="col"
              >
                Market
              </th>
              <th scope="col" className="txt-green py-3">
                Odds
              </th>
              <th
                scope="col"
                className="txt-green py-3"
                style={{ width: "140px" }}
              >
                Odds Trend
              </th>
              <th scope="col" className="txt-green py-3">
                Category
              </th>
              <th
                scope="col"
                className="txt-green py-3"
                onClick={() => requestSort("volume")}
                style={{ cursor: "pointer" }}
              >
                Volume{" "}
                {sortConfig.key === "volume"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th
                scope="col"
                className="txt-green py-3"
                onClick={() => requestSort("volatility")}
                style={{ cursor: "pointer" }}
              >
                2h Volatility{" "}
                {sortConfig.key === "volatility"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th
                scope="col"
                className="txt-green py-3"
                onClick={() => requestSort("volatility_last_24_hours")}
                style={{ cursor: "pointer" }}
              >
                24h Volatility{" "}
                {sortConfig.key === "volatility_last_24_hours"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th
                scope="col"
                className="txt-green py-3"
                onClick={() => requestSort("imp")}
                style={{ cursor: "pointer" }}
              >
                <span style={{ marginLeft: "8%" }}>
                  🚨{" "}
                  {sortConfig.key === "imp"
                    ? sortConfig.direction === "ascending"
                      ? "🔼"
                      : "🔽"
                    : ""}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedMarkets.map((obj, index) => (
              <tr key={index}>
                <td>
                  <a
                    className="text-success txt-green"
                    rel="noreferrer"
                    target="_blank"
                    href={`https://polymarket.com/event/${obj.slg}`}
                  >
                    <b>{obj.question}</b>
                  </a>
                </td>
                <td className="text-center">{Math.round(obj.odds * 100)}%</td>
                <td className="text-center">{obj.odds_trend}</td>
                <td className="text-center">{obj.classification}</td>
                <td className="text-center">${Math.round(obj.volume)}</td>
                <td className="text-center">{obj.volatility}</td>
                <td className="text-center">
                  {parseFloat((obj.volatility_last_24_hours ?? 0).toFixed(5)) ||
                    0}
                </td>
                <td className="text-center">
                  {Math.round(obj.volume * obj.volatility)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketTable;
