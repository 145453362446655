import { Link } from "react-router-dom";

function AboutPage() {
    return (
        <div className="container about my-5">
            {/* Hero Section */}
            <div className="hero">
                <div>
                    <Link to="/">Back</Link>
                </div>
                <div className="text-start mt-3">
                    <h1 className="txt-green">About PolyVol.info</h1>
                    <p className="mb-0">
                        I created this app to scratch an itch I had working on <a href="http://news.polymarket.com" target="_blank" rel="noopener noreferrer">The Oracle</a>, Polymarket's news arm.
                    </p>
                </div>
            </div>

            {/* Content Section */}
            <div className="ms-2">
                <h2 className="txt-green">How PolyVol.info Works</h2>
                <ul className="mt-3">
                    <li>
                        1) Queries the <a href="https://docs.polymarket.com/#introduction" target="_blank" rel="noopener noreferrer">Polymarket gamma API</a> every 5 minutes and records the odds for each market.
                    </li>
                    <li>2) Calculates the volatility in the odds over a 24-hour period.</li>
                    <li>3) Displays every market ranked by 24-hour volatility.</li>
                </ul>
            </div>

            <div className="mt-5">
                <h2 className="txt-green">Significance Metric</h2>
                <p>
                    Some of the most volatile markets were very small/illiquid, so the site adds a significance metric shown as "🚨" in the app.
                </p>
                <p>
                    This value is calculated as <strong>(volume * volatility)</strong>, which you can think of as "newsworthiness."
                </p>
            </div>

            {/* Filters Section */}
            <div className="mt-5">
                <h2 className="txt-green">You Can Filter Markets By:</h2>
                <ul className="mt-3">
                    <li>Volume</li>
                    <li>Volatility</li>
                    <li>🚨</li>
                    <li>
                        Category (Odds for sports markets are very volatile in-game, so they are unchecked by default)
                    </li>
                </ul>
            </div>

            {/* Next Steps Section */}
            <div className="mt-5">
                <h2 className="txt-green">Development Next Steps</h2>
                <ul className="mt-3">
                    <li>Auto filter low volume markets</li>
                    <li>Bug fixes</li>
                    <li>Fix scrolling ticker at the top</li>
                </ul>
            </div>

            {/* Contact Section */}
            <div className="footer mt-5">
                <p>
                    Ideas or want to contribute?{" "}
                    <a href="https://x.com/wasabiboat" target="_blank" rel="noopener noreferrer">
                        DM @wasabiboat
                    </a>
                </p>
            </div>
        </div>
    );
}

export default AboutPage;
